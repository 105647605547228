@import 'common-flagship_tasting_room';
@import 'common-non_commerce';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}

.b-footer{
	border-top:1px solid $color-footer-border;

	&_navigation-title {
		font-family: $font-alt;
		color: $color-shade_5;
	}
	&-newsletter {
		color: $color-shade_3;
	}
	&_social-title {
		color: $color-shade_5;
		@include media(sm) {
			text-align: center;
		}
	}
	&_navigation-link {
		color: $color-shade_4;
	}
	&-copyright {
		opacity: 1;
		font-size: 12px;
		color: $color-shade_6;
	}
	&_legal-links {
		color: $color-shade_4;
	}
	&_legal-link {
		font-size: 12px;
        color: $color-shade_4;
    }

	&-customer_service , &-need_help{
		visibility: hidden;
	}

	&_social {
		@include media(md-up) {
			padding-top: 64px;
		}
		&-links {
			color: $color-shade_2;
			justify-content: flex-start;
			@include media(sm){
				justify-content: center;
			}
		   & a {
				margin-right: 16px;
			}
		}
		.content-asset {
			@include media(sm) {
				border-top: none;
			}
		}
	}
}

.b-back_to_top {
	color: $color-shade_6 !important;

	&-icon {
		color: $color-shade_2;
	}
}

.b-header-utility_item.m-search {
	font-size: 16px;
	color: $color-shade_6;

	@include media(md-down) {
		font-size: 0;
	}
	
	&:before {
		background: var(--icon-color,$color-shade_2) !important;
	}
}

.b-categories_navigation-link_1 {
	font-size: 20px;
	color: $color-shade_4;
}

.b-header-links_item.m-stores {
	font-size: 16px;
	color: $color-shade_6;
	
	&:before {
		background: var(--icon-color,$color-shade_2) !important;
	}
}

.b-product_tile-image {
	background: none;
}

.b-header-hamburger_cta {
	color: $color-shade_2;
}

.b-product_badge {

	&-image {
		background: none !important;
		border: none;
	}
    &-text {
		font-size: 16px !important;
        color: $color-shade_5;
    }
    &-points {
		border-bottom: 3px solid $color-shade_2;
        color: $color-shade_4;
        font-size: 20px !important;
		padding: 0 10px 6px 10px;
    }
}

.b-carousel-pagination_control {
	border: 1px solid $color-shade_2;
	background-color: $color-primary-background;
}

.b-carousel-pagination_control.m-current{
	background-color: $color-shade_2;
	}

.b-breadcrumbs-link.m-current {
	color: var(--color-link, $color-shade_6) !important;
}

.b-store_locator-link {
	color: var(--color-link, $color-shade_5) !important;
}

.b-store_locator-submit {
	font-size: 20px;
}

.b-breadcrumbs {
	&-link {
		color: var(--color-link, $color-shade_4) !important;
	}
    &-item {
        align-items: baseline;
        &:not(.m-current) {
            @include media(md-up) {
                @include icon('arrow-right', 8px, 8px, after) {
                    background: $color-breadcrumb-separator_bg;
                    margin: 2px 15px 0;
                }
            }
        }
    }
	&-list {
		font-size: 16px !important;
		letter-spacing: 0.5px;
		line-height: 24px;
	}
}

.logo-desktop {
	@include media(sm){
		display: none;
	}
}
.logo-mobile {
	@include media(md-up) {
		display: none;
	}
}

.b-header-logo {
	@include media(sm){
		margin-left: 15px;
	}
}

.f-input_select-field {
	text-transform: none !important;
}

.g-button_main, .g-button_alt {
	font-family: $font-alt !important;
	font-size: 20px !important;
}

.b-tab_list-tab.m-active {
    border-bottom: 3px solid $color-shade_2;
}
.b-categories_navigation { 
    &-item_1:after {
        border-bottom: 3px solid $color-shade_2;        
    }
}


.b-header-navigation_link.m-stores{
	font-size: 20px;
	color: $color-shade_4;
}